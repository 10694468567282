
import { MeModule } from "@/store/modules/me";
import { PopoutsModule } from "@/store/modules/popouts";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  computed: {
    isConnected(): any {
      return MeModule.connected;
    },
    connectionMessage(): any {
      if (this.isConnected && !MeModule.connectionMessage) {
        return this.$t("connection.ready");
      }
      return MeModule.connectionMessage;
    },
  },
  watch: {
    isConnected: {
      handler: "closePopout",
    },
    connectionMessage: {
      handler: "onConnectionMessage",
    },
  },
  mounted() {
    this.closePopout();
  },
  methods: {
    closePopout() {
      window.setTimeout(() => {
        if (!this.isConnected) return;
        this.$emit("close");
      }, 3000);
    },
    onConnectionMessage(message) {
      if (message === "terms_not_agreed") {
        PopoutsModule.ShowPopout({
          id: "changes-policy-popout",
          component: "ChangesToPolicies",
        });
      }
    },
  },
});
